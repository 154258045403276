import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import '../assets/css/commandes.css';
import Sidebar from '../components/sidebar';
import { API_URL, generateHeaders } from './Const';
import useDeliveryCountAlert from '../components/useDeliveryCountAlert';

const getStatusClass = (status) => {
    switch (status) {
        case 0: return 'pending';
        case 1: return 'in-progress';
        case 2: return 'recovered';
        case 3: return 'delivered';
        default: return 'canceled';
    }
};

const getStatusText = (status) => {
    switch (status) {
        case 0: return 'En attente';
        case 1: return 'Prise en charge';
        case 2: return 'Récupéré';
        case 3: return 'Livré';
        default: return 'Annulé';
    }
};

const OrderDetailsPopup = ({ order, onClose }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Détails de la commande</h2>
                <div className="order-details">
                    <div className="detail-group">
                        <h3>Informations générales</h3>
                        <p><strong>Code:</strong> {order.code}</p>
                        <p><strong>Date:</strong> {new Date(order.createdAt).toLocaleString()}</p>
                        <p><strong>Zone de livraison:</strong> {order.delivery_zone}</p>
                        <p><strong>Statut:</strong> <span className={`status ${getStatusClass(order.statuses[0].actually_state)}`}>
                            {getStatusText(order.statuses[0].actually_state)}
                        </span></p>
                        <p><strong>{order.payment_method.description}:</strong> {order.checkout.status}</p>
                    </div>
                    <div className="detail-group">
                        <h3>Expéditeur</h3>
                        <p><strong>Nom:</strong> {order.sender_name}</p>
                        <p><strong>Téléphone:</strong> {order.sender_number}</p>
                        <p><strong>Email:</strong> {order.sender_mail}</p>
                        <p><strong>Adresse:</strong> {order.sender_adress_detail}</p>
                    </div>
                    <div className="detail-group">
                        <h3>Destinataire</h3>
                        <p><strong>Nom:</strong> {order.recipient_name}</p>
                        <p><strong>Téléphone:</strong> {order.recipient_number}</p>
                        <p><strong>Email:</strong> {order.recipient_mail}</p>
                        <p><strong>Adresse:</strong> {order.recipient_adress_detail}</p>
                    </div>
                    <div className="detail-group">
                        <h3>Détails de la commande</h3>
                        <p><strong>Catégorie:</strong> {order.category}</p>
                        <p><strong>Montant:</strong> {order.amount} FCFA</p>
                        <p><strong>Méthode de paiement:</strong> {order.payment_method ? order.payment_method.description : 'Non spécifié'}</p>
                        <p><strong>Statut du paiement:</strong> {order.checkout ? order.checkout.status : 'Non spécifié'}</p>
                    </div>
                </div>
                <button onClick={onClose} className="popup-close-btn">Fermer</button>
            </div>
        </div>
    );
};

const AssignmentPopup = ({ order, onClose, onAssign }) => {
    const [availableDeliverymen, setAvailableDeliverymen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDeliveryman, setSelectedDeliveryman] = useState(null);

    useEffect(() => {
        const fetchAvailableDeliverymen = async () => {
            try {
                const response = await axios.get(`${API_URL}/admin/getAvailableDeliverymen`, { headers: generateHeaders() });
                setAvailableDeliverymen(response.data.availableDeliverymen);
            } catch (error) {
                console.error('Erreur lors de la récupération des livreurs disponibles:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAvailableDeliverymen();
    }, []);

    const handleAssign = async () => {
        if (!selectedDeliveryman) return;
        try {
            await axios.post(`${API_URL}/admin/assignDelivery`, {
                deliveryCode: order.code,
                deliverymanId: selectedDeliveryman.id
            }, { headers: generateHeaders() });
            onAssign();
        } catch (error) {
            console.error('Erreur lors de l\'assignation du livreur:', error);
        }
    };

    if (loading) return <div>Chargement des livreurs disponibles...</div>;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                maxWidth: '500px',
                width: '100%'
            }}>
                <h2 style={{ marginBottom: '20px' }}>Assigner un livreur</h2>
                <p style={{ marginBottom: '10px' }}>Commande: {order.code}</p>
                <select
                    value={selectedDeliveryman ? selectedDeliveryman.id : ''}
                    onChange={(e) => setSelectedDeliveryman(availableDeliverymen.find(d => d.id === parseInt(e.target.value)))}
                    style={{
                        width: '100%',
                        padding: '10px',
                        marginBottom: '20px',
                        borderRadius: '4px',
                        border: '1px solid #ccc'
                    }}
                >
                    <option value="">Sélectionnez un livreur</option>
                    {availableDeliverymen.map(deliveryman => (
                        <option key={deliveryman.id} value={deliveryman.id}>
                            {deliveryman.fullname} ({deliveryman.number})
                        </option>
                    ))}
                </select>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <button
                        onClick={onClose}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#f44336',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Annuler
                    </button>
                    <button
                        onClick={handleAssign}
                        disabled={!selectedDeliveryman}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: selectedDeliveryman ? '#4CAF50' : '#ccc',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: selectedDeliveryman ? 'pointer' : 'not-allowed'
                        }}
                    >
                        Assigner
                    </button>
                </div>
            </div>
        </div>
    );
};

const OrderListDashboard = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [assigningOrder, setAssigningOrder] = useState(null);
    const ordersPerPage = 10;
    const { deliveryCount, hasNewDeliveries, newDeliveries, resetAlert } = useDeliveryCountAlert();

    useEffect(() => {
        if (hasNewDeliveries) {
            // Alerte personnalisée
            const alertMessage = newDeliveries === 1
                ? "Une nouvelle commande a été ajoutée !"
                : `${newDeliveries} nouvelles commandes ont été ajoutées !`;
            
            // Vous pouvez remplacer cet alert par une notification plus élégante si vous le souhaitez
            alert(alertMessage);
            
            resetAlert();
            fetchOrders(); // Recharger la liste des commandes
        }
    }, [hasNewDeliveries, newDeliveries]);

    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${API_URL}/admin/getAdminDeliveries`, { headers: generateHeaders() });
            // Trier les commandes du plus récent au plus ancien
            const sortedOrders = (response.data.deliveries || []).sort((a, b) => 
                new Date(b.createdAt) - new Date(a.createdAt)
            );
            setOrders(sortedOrders);
        } catch (err) {
            console.error('Erreur lors du chargement des commandes:', err);
            setOrders([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const filteredOrders = useMemo(() => {
        return orders.filter(order => {
            const matchesFilter = filter === 'all' || order.statuses[0].actually_state === parseInt(filter);
            const matchesSearch = order.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
                order.sender_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                order.recipient_name.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesFilter && matchesSearch;
        });
    }, [orders, filter, searchTerm]);

    const pageCount = Math.ceil(filteredOrders.length / ordersPerPage);
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleViewOrder = (order) => {
        setSelectedOrder(order);
    };

    const handleAssignOrder = (order) => {
        setAssigningOrder(order);
    };

    const handleAssignSuccess = () => {
        setAssigningOrder(null);
        fetchOrders(); // Recharger les commandes après l'assignation
    };

    if (loading) return <div>Chargement...</div>;

    return (
        <>
            <Sidebar />
            <main className="main-content">
                <header className="main-header">
                    <input
                        type="search"
                        placeholder="Rechercher un colis"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="user-profile">
                        <h3>Bienvenue sur Happy {sessionStorage.getItem('email')}</h3>
                    </div>
                </header>

                <section className="order-list">
                    <header>
                        <h1>Liste des commandes</h1>
                    </header>

                    <div className="filters">
                        <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                            <option value="all">Tous les états</option>
                            <option value="0">En attente</option>
                            <option value="1">Prise en charge</option>
                            <option value="2">Récupéré</option>
                            <option value="3">Livré</option>
                            <option value="4">Annulé</option>
                        </select>
                    </div>

                    {currentOrders.length === 0 ? (
                        <div className="no-orders-message">
                            {filter === 'all'
                                ? "Aucune commande correspondante trouvée."
                                : `Aucune commande avec l'état "${getStatusText(parseInt(filter))}" trouvée.`}
                        </div>
                    ) : (
                        <table className="order-table">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Date</th>
                                    <th>Expéditeur</th>
                                    <th>Destinataire</th>
                                    <th>Zone</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentOrders.map((order) => (
                                    <tr key={order.id}>
                                        <td>{order.code}</td>
                                        <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                                        <td>{order.sender_name}</td>
                                        <td>{order.recipient_name}</td>
                                        <td>{order.delivery_zone}</td>
                                        <td>
                                            <span className={`status ${getStatusClass(order.statuses[0].actually_state)}`}>
                                                {getStatusText(order.statuses[0].actually_state)}
                                            </span>
                                        </td>
                                        <td>
                                            <button onClick={() => handleViewOrder(order)} className="view-button">👁 Voir</button>
                                            {order.statuses[0].actually_state === 0 && (
                                                <button onClick={() => handleAssignOrder(order)} className="assign-button">📦 Assigner</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {filteredOrders.length > ordersPerPage && (
                        <div className="pagination">
                            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>‹</button>
                            {[...Array(pageCount)].map((_, i) => (
                                <button
                                    key={i}
                                    onClick={() => handlePageChange(i + 1)}
                                    className={currentPage === i + 1 ? 'active' : ''}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>›</button>
                        </div>
                    )}
                </section>
            </main>
            {selectedOrder && (
                <OrderDetailsPopup
                    order={selectedOrder}
                    onClose={() => setSelectedOrder(null)}
                />
            )}
            {assigningOrder && (
                <AssignmentPopup
                    order={assigningOrder}
                    onClose={() => setAssigningOrder(null)}
                    onAssign={handleAssignSuccess}
                />
            )}
        </>
    );
};

export default OrderListDashboard;