import React, { useState, useEffect, useCallback } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import axios from 'axios';
import '../assets/css/dashboard.css';
import Sidebar from '../components/sidebar';
import { API_URL, generateHeaders } from './Const';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalOrders: 0,
        totalClients: 0,
        totalDeliverymen: 0,
        totalDeliveries: 0,
        orderStatusCounts: {},
        bestDeliverymen: []
    });
    const [orderStats, setOrderStats] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(async () => {
        try {
            const [ordersResponse, clientsResponse, deliverymenResponse] = await Promise.all([
                axios.get(`${API_URL}/admin/getAdminDeliveries`, { headers: generateHeaders() }),
                axios.get(`${API_URL}/admin/getCustomers`, { headers: generateHeaders() }),
                axios.get(`${API_URL}/admin/getDeliverymen`, { headers: generateHeaders() })
            ]);

            const orders = ordersResponse.data.deliveries || [];
            const clients = clientsResponse.data.customers || [];
            const deliverymen = deliverymenResponse.data.deliverymen || [];

            // Calculer les statistiques des états de commandes
            const orderStatusCounts = orders.reduce((acc, order) => {
                const status = order.statuses[0].actually_state;
                acc[status] = (acc[status] || 0) + 1;
                return acc;
            }, {});

            setStats({
                totalOrders: orders.length,
                totalClients: clients.length,
                totalDeliverymen: deliverymen.length,
                totalDeliveries: orders.length,
                orderStatusCounts,
                bestDeliverymen: deliverymen.slice(0, 3).map(d => d.fullname)
            });

            // Calculer les statistiques de commandes pour les 7 derniers jours
            const last7Days = getLastNDays(7);
            const orderStatsByDay = last7Days.map(date => {
                const count = orders.filter(order => 
                    new Date(order.createdAt).toDateString() === date.toDateString()
                ).length;
                return { date, count };
            });

            setOrderStats(orderStatsByDay.map(stat => stat.count));

            setLoading(false);
        } catch (error) {
            console.error("Erreur lors du chargement des données:", error);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
        
        // Configurer l'intervalle pour recharger les données toutes les minutes
        const intervalId = setInterval(fetchData, 60000);

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [fetchData]);

    const getLastNDays = (n) => {
        const result = [];
        for (let i = n - 1; i >= 0; i--) {
            const d = new Date();
            d.setDate(d.getDate() - i);
            result.push(d);
        }
        return result;
    };

    const orderStatsData = {
        labels: getLastNDays(7).map(date => date.toLocaleDateString('fr-FR', { weekday: 'long' })),
        datasets: [{
            label: 'Commandes',
            data: orderStats,
            borderColor: '#EA542B',
            fill: false,
            tension: 0.1
        }]
    };

    const deliveryStatsData = {
        labels: ['En attente', 'En cours', 'Récupéré', 'Livré', 'Annulé'],
        datasets: [{
            label: 'États des commandes',
            data: [
                stats.orderStatusCounts[0] || 0,
                stats.orderStatusCounts[1] || 0,
                stats.orderStatusCounts[2] || 0,
                stats.orderStatusCounts[3] || 0,
                stats.orderStatusCounts[4] || 0
            ],
            backgroundColor: ['#FFA500', '#3498db', '#9b59b6', '#4caf50', '#EA542B']
        }]
    };

    if (loading) return <div>Chargement...</div>;

    return (
        <>
            <Sidebar/>
            <main className="main-content">
                <header className="main-header">
                    <input type="search" placeholder="Rechercher un colis" />
                    <div className="user-profile">
                        <h3>Bienvenue sur Happy {sessionStorage.getItem('email')}</h3>
                    </div>
                </header>
                <section className="stats-cards">
                    {[
                        { icon: "fa-shopping-cart", value: stats.totalOrders, label: "Total Commandes" },
                        { icon: "fa-users", value: stats.totalClients, label: "Total Clients" },
                        { icon: "fa-motorcycle", value: stats.totalDeliverymen, label: "Livreurs" },
                        { icon: "fa-box", value: stats.totalDeliveries, label: "Livraisons" }
                    ].map((stat, index) => (
                        <div className="card" key={index}>
                            <i className={`icon fas ${stat.icon}`}></i>
                            <p>{stat.value}</p>
                            <span>{stat.label}</span>
                        </div>
                    ))}
                </section>

                <div className="row">
                    <section className="client-statistics">
                        <h2>Statistiques des commandes</h2>
                        <div className="chart-container">
                            <Line data={orderStatsData} options={{ maintainAspectRatio: false, responsive: true }} />
                        </div>
                        <select>
                            <option value="juillet">Juillet</option>
                        </select>
                    </section>

                    <section className="delivery-statistics">
                        <h2>États des commandes</h2>
                        <div className="chart-container">
                            <Pie data={deliveryStatsData} options={{ maintainAspectRatio: false, responsive: true }} />
                        </div>
                    </section>
                </div>

                <div className="row">
                    <section className="performance">
                        <h2>Performance de livraison</h2>
                        <ul>
                            <li>Colis en attente d'assignation: {stats.orderStatusCounts[0] || 0}</li>
                            <li>Colis en cours de livraison: {stats.orderStatusCounts[1] || 0}</li>
                            <li>Colis livrés: {stats.orderStatusCounts[3] || 0}</li>
                        </ul>
                    </section>

                    <section className="best-deliverers">
                        <h2>Top 3 des Meilleurs livreurs du mois</h2>
                        <ul>
                            {stats.bestDeliverymen.map((name, index) => (
                                <li key={index}>
                                    <span>{name}</span>
                                    <span>Livreur</span>
                                </li>
                            ))}
                        </ul>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Dashboard;