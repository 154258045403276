import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import '../assets/css/livreurs.css';
import Sidebar from '../components/sidebar';
import { API_URL, generateHeaders } from './Const';

const AddDeliverymanPopup = ({ onClose, onSuccess, onError }) => {
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        number: '',
        password: '',
        zone: '',
        nni: '',
        adresse: '',
        date_of_birth: '2000-01-01',
        picture: null
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: files ? files[0] : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        try {
            const response = await axios.post(`${API_URL}/admin/createDeliveryman`, data, {
                headers: {
                    ...generateHeaders(),
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 201) {
                onSuccess(`Enregistrement du livreur ${formData.fullname} avec succès`);
                onClose();
            }
        } catch (error) {
            console.error('Erreur lors de l\'ajout du livreur:', error);
            onError(error.response?.data?.message || "Une erreur est survenue lors de l'ajout du livreur");
            onClose();
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Ajouter un nouveau livreur</h2>
                <form onSubmit={handleSubmit} className="add-deliveryman-form">
                    <div className="form-group">
                        <label htmlFor="fullname">Nom complet</label>
                        <input type="text" id="fullname" name="fullname" value={formData.fullname} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="number">Numéro de téléphone</label>
                        <input type="tel" id="number" name="number" value={formData.number} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zone">Zone</label>
                        <input type="text" id="zone" name="zone" value={formData.zone} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="nni">NNI</label>
                        <input type="text" id="nni" name="nni" value={formData.nni} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="adresse">Adresse</label>
                        <input type="text" id="adresse" name="adresse" value={formData.adresse} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="date_of_birth">Date de naissance</label>
                        <input type="date" id="date_of_birth" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="picture">Photo</label>
                        <input type="file" id="picture" name="picture" onChange={handleChange} accept="image/*" required />
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="submit-btn">Ajouter</button>
                        <button type="button" onClick={onClose} className="cancel-btn">Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const DeliveryListDashboard = () => {
    const [deliverymen, setDeliverymen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const deliverymenPerPage = 10;

    const fetchDeliverymen = async () => {
        try {
            const response = await axios.get(`${API_URL}/admin/getDeliverymen`, { headers: generateHeaders() });
            setDeliverymen(response.data.deliverymen || []);
        } catch (err) {
            console.error('Erreur lors du chargement des livreurs:', err);
            setDeliverymen([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDeliverymen();
    }, []);

    const filteredDeliverymen = useMemo(() => {
        return deliverymen.filter(deliveryman => 
            deliveryman.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            deliveryman.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            deliveryman.number.includes(searchTerm) ||
            deliveryman.nni.includes(searchTerm)
        );
    }, [deliverymen, searchTerm]);

    const pageCount = Math.ceil(filteredDeliverymen.length / deliverymenPerPage);
    const indexOfLastDeliveryman = currentPage * deliverymenPerPage;
    const indexOfFirstDeliveryman = indexOfLastDeliveryman - deliverymenPerPage;
    const currentDeliverymen = filteredDeliverymen.slice(indexOfFirstDeliveryman, indexOfLastDeliveryman);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAddSuccess = (message) => {
        setSuccessMessage(message);
        setErrorMessage('');
        fetchDeliverymen();
    };

    const handleAddError = (message) => {
        setErrorMessage(message);
        setSuccessMessage('');
    };

    if (loading) return <div>Chargement...</div>;

    return (
        <>
            <Sidebar/>
            <main className="main-content">
                <header className="main-header">
                    <input 
                        type="search" 
                        placeholder="Rechercher un livreur" 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="user-profile">
                        <h3>Bienvenue sur Happy {sessionStorage.getItem('email')}</h3>
                    </div>
                </header>
                <section className="delivery-list">
                    <header>
                        <h1>Liste complète des livreurs</h1>
                        <button className="add-client-btn" onClick={() => setShowAddPopup(true)}>Ajouter un livreur</button>
                    </header>
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}

                    {currentDeliverymen.length === 0 ? (
                        <div className="no-deliverymen-message">
                            Aucun livreur trouvé.
                        </div>
                    ) : (
                        <table className="delivery-table">
                            <thead>
                                <tr>
                                    <th>Nom complet</th>
                                    <th>NNI</th>
                                    <th>Numéro de téléphone</th>
                                    <th>Email</th>
                                    <th>Adresse</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentDeliverymen.map((deliveryman) => (
                                    <tr key={deliveryman.id}>
                                        <td>{deliveryman.fullname}</td>
                                        <td>{deliveryman.nni}</td>
                                        <td>{deliveryman.number}</td>
                                        <td>{deliveryman.email}</td>
                                        <td>{deliveryman.adresse}</td>
                                        <td>
                                            <a href="#"><i className="view-icon">👁</i></a>
                                            <a href="#"><i className="edit-icon">✏️</i></a>
                                            <a href="#"><i className="delete-icon">🗑</i></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {filteredDeliverymen.length > deliverymenPerPage && (
                        <div className="pagination">
                            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>‹</button>
                            {[...Array(pageCount)].map((_, i) => (
                                <button 
                                    key={i} 
                                    onClick={() => handlePageChange(i + 1)}
                                    className={currentPage === i + 1 ? 'active' : ''}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>›</button>
                        </div>
                    )}
                </section>
            </main>
            {showAddPopup && 
                <AddDeliverymanPopup 
                    onClose={() => setShowAddPopup(false)} 
                    onSuccess={handleAddSuccess}
                    onError={handleAddError}
                />
            }
        </>
    );
};

export default DeliveryListDashboard;